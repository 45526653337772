import PropTypes from 'prop-types';
import React, { useMemo } from "react";

import { Box, Button, Typography } from "@material-ui/core";
import { AboutSectionContainer } from "@product-site-frontend/shared";
import { graphql } from "gatsby";

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import PageBox from "../components/PageBox";
import SEO from "../components/SEO";

PromotionsPage.propTypes = {
  data: PropTypes.object,
};

export default function PromotionsPage({ data }) {
  const styles = useMemo(() => ({
    wrapperItems: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      justifyContent: 'center',
    },
    item: {
      maxWidth: '465px',
      flex: {
        xs: '0 0 100%',
        sm: '0 0 100%',
        md: '0 0 49%',
        lg: '0 0 23%',
      },
      border: '1px solid #f8f8f8',
      borderRadius: '4px',
      overflow: 'hidden',
      paddingBottom: '20px',
    },
    wrapperImage: {
      marginBottom: '15px',
    },
    title: {
      fontSize: '18px !important',
      lineHeight: 1,
      marginBottom: '15px',
      fontWeight: 'normal',
      textAlign: 'center',
      height: '55px',
      overflow: 'hidden',
      paddingLeft: '20px',
      paddingRight: '20px',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '3',
      textOverflow: 'ellipsis'
    },
    link: {
      padding: '5px 15px',
      border: '1px solid #f6863a',
      textDecoration: 'none',
      borderRadius: '4px',
      margin: '30px auto 0',
      display: 'block',
      maxWidth: '100px',
      textAlign: 'center',
      cursor: 'pointer',
    }
  }), []);

  const renderItem = elem => {
    return elem.map(elem => {
      return (
        <Box key={elem.slug} sx={styles.item}>
          <Box sx={{ ...styles.wrapperImage }}>
            <img alt={elem.title} src={elem.preview.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
          </Box>
          <Typography sx={styles.title} variant="h2">{elem.seo.title}</Typography>
          <Button component="a" href={`${process.env.GATSBY_ROOT_URL}/promotions/${elem.slug}`} sx={styles.link}>Читать</Button>
        </Box>
      )
    })
  }

  return (
    <PageBox>
      <SEO pageData={{
        title: 'Акция на лизинг от Аренза',
        description: 'Акция на лизинг оборудования онлайн для юридических лиц от Аренза',
      }} />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Акции'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />
      <AboutSectionContainer sxContainer={{ mb: { xs: 0, lg: 0 } }}>
        <Typography sx={{ mb: { xs: 0, md: 0, lg: 0 } }} variant="h1">
          Акции
        </Typography>
      </AboutSectionContainer>

      <AboutSectionContainer sxContainer={{ mb: { xs: 0, lg: 0 } }}>
        <Box sx={styles.wrapperItems}>
          {renderItem(data.promotions.nodes)}
        </Box>
      </AboutSectionContainer>
    </PageBox>
  );
}

export const query = graphql`
  query PromotionsPageQuery {
    promotions: allStrapiPromotions(sort: { order: DESC, fields: strapiId }) {
      nodes {
        seo {
          title
          description
        }
        slug
        preview {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
  }
`;
